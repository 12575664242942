import { useEffect, RefObject } from 'react'

/**
 * Makes element scrollable when the user hovers on it.
 * @TODO set speed
 */
export const useScrollOnHover = <T extends HTMLDivElement>(
  ref: RefObject<T>
) => {
  useEffect(() => {
    let event: NodeJS.Timer

    if (!ref.current) {
      return
    }

    ref.current?.addEventListener('mouseenter', () => {
      if (event) {
        clearInterval(event)
      }

      event = setInterval(() => {
        if (ref.current) {
          ref.current.scrollTo({
            left: ref.current.scrollLeft + 1
          })
        }
      }, 10)
    })

    ref.current?.addEventListener('mouseleave', () => {
      if (ref.current) {
        ref.current.scrollTo({ left: 0 })
      }

      clearInterval(event)
    })

    return () => clearInterval(event)
  }, [ref])
}
