import i18n from 'i18n'
import { alert } from 'joist/modules/alerts'
import { captureException } from 'sentry'
import { store } from 'store'
import { authSelectors } from 'store/auth'
import { spacesSelectors } from 'store/spaces'

export const t = i18n.t

export const notify = alert(store.dispatch)

export const dispatch = store.dispatch

export function handleWriteErrorFactory(callback: (e?: Error) => void) {
  return function (e: Error, extra?: Record<string, any>) {
    captureException(e, {
      contexts: { extra, error: { message: e.message, name: e.name } }
    })
    notify({
      message: t('alerts.writeError'),
      type: 'error',
      timeout: 3000
    })
    callback(e)
  }
}

export function getSpaceId() {
  const state = store.getState()
  return spacesSelectors.currentSpaceId(state)
}

export function getUid() {
  const state = store.getState()
  return authSelectors.uid(state)
}
