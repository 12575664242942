import { styled } from '../../theme/stitches.config'

export const FormGroup = styled('div', {
  variants: {
    orientation: {
      vertical: {
        stack: '$paddingBetweenMed'
      },
      horizontal: {
        stackH: '$paddingBetweenMed',
        alignItems: 'center'
      }
    }
  },
  defaultVariants: {
    orientation: 'vertical'
  }
})
