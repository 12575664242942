import React, { ComponentProps, ElementRef } from 'react'
import { IconButton } from '@sc/components/Button'
import { Icon } from '@sc/components/Icon'
import { useOverflowHandler } from '@sc/hooks/useOverflowHandler'
import { styled } from '../theme/stitches.config'
import { Div } from './Div'

export const RowSegment = styled('div', {
  alignItems: 'center',
  fontSize: '$base',
  variants: {
    /**
     * Fit all the container width
     */
    stretch: { true: {} } // No styles needed, we just want this prop to be in the type definition
  }
})

export const BaseRow = styled('div', {
  display: 'grid',
  $$spacing: '$space$paddingBetweenSmall',

  [`${RowSegment}`]: {
    stackH: '$$spacing'
  },

  variants: {
    /**
     * When `true`, allows hover on `Row` component.
     * It will also be `true` when `href` or `onClick` are defined.
     */
    hoverable: {
      true: {
        '&:hover': {
          backgroundColor: '$actionTertiary',
          cursor: 'pointer'
        }
      }
    },
    /**
     * - `large`
     * - `small`
     */
    variant: {
      large: {
        gridTemplateRows:
          'minmax(calc($infoMinHeight - calc($space$paddingContainerLevelOne * 2)),1fr)',
        paddingY: '$paddingContainerLevelOne'
      },
      small: {
        gridTemplateRows: 'minmax($controlHeight,1fr)'
      }
    },

    /**
     * Only applicable to `small` variant. Sets the padding to 0.
     */
    paddingZero: {
      true: {
        padding: 0
      }
    },

    /**
     * Toggles the active state of the component
     */
    active: {
      true: {
        backgroundColor: '$actionTertiary !important'
      }
    }
  },

  compoundVariants: [
    {
      variant: 'small',
      paddingZero: false,
      css: {
        paddingLeft: '$paddingUIXL',
        paddingRight: '$paddingUIXL'
      }
    }
  ],

  defaultVariants: {
    hoverable: false,
    variant: 'large',
    paddingZero: false
  }
})

export const StyledRowLeft = styled(RowSegment, {
  gridColumn: '1',
  justifyContent: 'flex-start',
  variants: {
    /**
     * Avoids text breaking, with a scroll implementation
     */
    preventLineBreak: {
      true: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        maskImage:
          'linear-gradient(to right, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0.1) 93%, transparent)',
        paddingRight: '9rem', // Needed to show the complete content without mask
        maskPosition: 'right',
        '&::-webkit-scrollbar': {
          height: 0
        },
        // Needed to ensure correct positioning
        // Might not be the best practice
        '& > *': {
          width: 'auto'
        }
      }
    }
  }
})

export type RowLeftProps = ComponentProps<typeof StyledRowLeft> & {
  arrowOnOverflow?: boolean
}

const RowLeftWithOverflow = (props: ComponentProps<typeof StyledRowLeft>) => {
  const { ref, isOnBorders, showArrows, moveRightDisabled, moveRight } =
    useOverflowHandler()

  return (
    <Div
      css={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        overflow: 'auto'
      }}
    >
      <StyledRowLeft
        preventLineBreak
        onScroll={isOnBorders}
        ref={ref}
        {...props}
      />

      {showArrows && !moveRightDisabled ? (
        // Only show it on Desktop viewport
        <IconButton
          onClick={moveRight}
          variant="secondary"
          css={{
            position: 'absolute',
            right: '$paddingUIS',
            display: 'none',
            '@sm': {
              display: 'flex'
            }
          }}
        >
          <Icon name="arrowRight" />
        </IconButton>
      ) : null}
    </Div>
  )
}

export const RowLeft = ({ arrowOnOverflow, ...props }: RowLeftProps) => {
  return arrowOnOverflow ? (
    <RowLeftWithOverflow {...props} />
  ) : (
    <StyledRowLeft {...props} />
  )
}

export const RowCenter = styled(RowSegment, {
  gridColumn: '2',
  whiteSpace: 'nowrap',
  justifyContent: 'center'
})

export const RowRight = styled(RowSegment, {
  gridColumn: '3',
  justifyContent: 'flex-end'
})

export type RowCenterProps = ComponentProps<typeof RowCenter>
export type RowRightProps = ComponentProps<typeof RowRight>

// Row can have 1, 2, or 3 children. We can't get more specific than JSX.Element
type RowChildren =
  | JSX.Element
  | [JSX.Element]
  | [JSX.Element, JSX.Element]
  | [JSX.Element, JSX.Element, JSX.Element]

const columnTypes = [RowLeft, RowCenter, RowRight] as const

/**
 * Returns value for gridTemplateColumns based on the the children and their stretch property.
 */
const getTemplate = (children: RowChildren) => {
  // Normalise single child to array
  const childArray: JSX.Element[] = Array.isArray(children)
    ? children
    : [children]

  // If there are only two children and they are not RowLeft & RowRight
  if (
    childArray.length === 2 &&
    !(childArray[0].type === RowLeft && childArray[1].type === RowRight)
  ) {
    // We force 3 columns of equal width so that RowCenter is centered
    return '1fr 1fr 1fr'
    // If we did this for the RowLeft & RowRight case, those cols would be forced to only
    // take up 1/3 of the row each but we want them to take 1/2 or allow one to stretch
  }

  const someStretch = childArray.some(({ props }) => props.stretch)

  // If there are no stretched children and they are 3, make them have the same width
  if (childArray.length === 3 && !someStretch) {
    return '1fr 1fr 1fr'
  }

  const getColumnValue = (column: number) => {
    const child = childArray.find(({ type }) => type === columnTypes[column])

    // no child for this column - Use 'min-content' to make it collapse
    if (!child) return 'min-content'

    // Some children have `stretch` but this is not one of them - Use 'min-content' to make it collapse
    if (someStretch && !child.props.stretch) return 'min-content'

    // Use 'auto' to fill available space
    return 'auto'
  }

  return [getColumnValue(0), getColumnValue(1), getColumnValue(2)].join(' ')
}

type BaseRowProps = ComponentProps<typeof BaseRow>

type BaseRowRef = ElementRef<typeof BaseRow>
export type RowProps = Omit<BaseRowProps, 'children'> & {
  children: RowChildren // Override children to allow only RowSegments
}

export const Row = React.forwardRef<BaseRowRef, RowProps>(
  ({ css = {}, ...props }, ref) => {
    if ('gridTemplateColumns' in props) {
      throw new Error(
        'gridTemplateColumns is managed by Row. Use BaseRow if you need to manage this property'
      )
    }
    css.gridTemplateColumns =
      css.gridTemplateColumns || getTemplate(props.children)
    const hoverable = 'href' in props || 'onClick' in props

    return <BaseRow ref={ref} css={css} hoverable={hoverable} {...props} />
  }
) as typeof BaseRow
