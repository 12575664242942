import { styled } from '@sc/theme/stitches.config'

/**
 * Unstyled Div element to be used with
 * Theme values
 */
export const Div = styled('div', {
  boxSizing: 'border-box',
  padding: 0
})
