import React, { ComponentProps, ElementRef } from 'react'
import { Button, ButtonProps } from '@sc/components/Button'
import { Overlay } from '@sc/components/Overlay'
import { Row, RowLeft, RowCenter, RowRight } from '@sc/components/Row'
import { Body } from '@sc/components/Typography'
import { CSS, keyframes, styled } from '@sc/theme/stitches.config'
import { Dialog } from '../../primitives/Dialog'

const popIn = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.9)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
})

const ModalOverlay = styled(Dialog.Overlay, Overlay)

const ModalTitleContainer = styled(Row, {
  color: '$text',
  textAlign: 'center',
  verticalAlign: 'middle',
  backgroundColor: '$surfaceBg',
  borderTopLeftRadius: '$M',
  borderTopRightRadius: '$M',
  margin: 0
})

const StyledTitle = styled(Dialog.Title, Body, {
  margin: 0,
  color: '$fgHigh'
})

type ModalTitleRef = ElementRef<typeof ModalTitleContainer>
type ModalTitleProps = ComponentProps<typeof StyledTitle> & {
  containerCss?: ComponentProps<typeof ModalTitleContainer>['css']
}

const ModalTitle = React.forwardRef<ModalTitleRef, ModalTitleProps>(
  ({ containerCss, ...props }, ref) => (
    <ModalTitleContainer ref={ref} css={containerCss}>
      <RowCenter>
        <StyledTitle {...props} />
      </RowCenter>
    </ModalTitleContainer>
  )
)

const ModalActions = styled(Row, {
  borderBottomLeftRadius: '$M',
  borderBottomRightRadius: '$M',
  backgroundColor: '$surfaceBg',
  padding: '$paddingContainerLevelOne'
})

const ModalSection = styled('div', {
  padding: '$paddingContainerLevelOne',
  stack: '$paddingBetweenXL'
})

const ModalClose = (props: ButtonProps) => (
  <Button as={Dialog.Close} {...props} />
)

export const modalStyles: CSS = {
  position: 'fixed',
  top: '$paddingContainerLevelOne',
  left: '50%',
  transform: 'translateX(-50%)',
  outline: 'none',
  zIndex: '$modal',
  backgroundColor: '$surfaceFg',
  border: 'none',
  width: '95%',
  borderRadius: '$M',
  boxShadow: '$levelOne',
  pointerEvents: 'auto',
  '@sm': {
    width: 'min($articleWidthXS, 95%)',
    top: '$sizes$infoMinHeight'
  },

  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${popIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform'
  }
}

const BaseModal = styled(Dialog.Content, {
  ...modalStyles
})

type ModalRef = ElementRef<typeof Dialog.Content>

export type ModalProps = Dialog.DialogContentProps & {
  container?: Dialog.DialogPortalProps['container']
  css?: CSS
}

const ModalContent = React.forwardRef<ModalRef, ModalProps>(
  ({ children, container, ...props }, forwardedRef) => {
    return (
      <Dialog.Portal container={container}>
        <ModalOverlay />
        <BaseModal ref={forwardedRef} {...props}>
          {children}
        </BaseModal>
      </Dialog.Portal>
    )
  }
)

export const Modal = {
  Root: Dialog.Root,
  Actions: ModalActions,
  ActionsCenter: RowCenter,
  ActionsLeft: RowLeft,
  ActionsRight: RowRight,
  Content: ModalContent,
  Close: ModalClose,
  Section: ModalSection,
  Title: ModalTitle,
  Trigger: Dialog.Trigger
}
