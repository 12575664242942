import { useState, useRef, useEffect, UIEventHandler } from 'react'

const CONTENT_OFFSET = 150

/**
 * Allows to control the scroll of a component with
 * a `button` or other external component. Also returns values
 * to validate whether the component is scrollable.
 *
 * @param contentOffset
 * The offset amount by which the component will be scrolled on click.
 *
 * @todo revalidate on resize viewport.
 */
export const useOverflowHandler = (contentOffset = CONTENT_OFFSET) => {
  const ref = useRef<HTMLDivElement>(null)

  const [moveLeftDisabled, setMoveLeftDisabled] = useState(true)
  const [moveRightDisabled, setMoveRightDisabled] = useState(false)
  const [showArrows, setShowArrows] = useState(true)

  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current

      if (
        typeof scrollWidth !== 'undefined' &&
        typeof clientWidth !== 'undefined'
      ) {
        const hasOverflown = scrollWidth > clientWidth

        setShowArrows(hasOverflown)
      }
    }
  }, [ref])

  const moveLeft = () => {
    ref.current?.scrollBy({ left: -contentOffset, behavior: 'smooth' })
  }

  const moveRight = () => {
    ref.current?.scrollBy({ left: contentOffset, behavior: 'smooth' })
  }

  const isOnBorders: UIEventHandler<HTMLDivElement> = (e) => {
    if (e.currentTarget) {
      const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget

      // Left
      if (scrollLeft === 0) {
        setMoveLeftDisabled(true)
      } else {
        setMoveLeftDisabled(false)
      }

      // Right
      if (scrollWidth - scrollLeft <= clientWidth) {
        setMoveRightDisabled(true)
      } else {
        setMoveRightDisabled(false)
      }
    }
  }

  return {
    ref,
    moveLeftDisabled,
    moveRightDisabled,
    showArrows,
    /**
     * Should be added to the `onScroll` event of the component.
     */
    isOnBorders,
    moveLeft,
    moveRight
  }
}
