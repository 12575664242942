import { Body } from '../Typography'

export type FieldError = {
  message?: string
}

export type FormErrorProps = {
  id: string
  error?: FieldError
}

export const FormError = ({ error, id }: FormErrorProps) => {
  if (error && error.message) {
    return (
      <Body
        id={id}
        as="span"
        variant="small"
        css={{ color: '$dangerSolid', display: 'block' }}
      >
        {error.message}
      </Body>
    )
  }

  return null
}
