import { keyframes, styled } from '../theme/stitches.config'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})

export const Overlay = styled('div', {
  backgroundColor: '$overlay',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  inset: 0,
  zIndex: '$modal',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
})
