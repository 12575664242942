import React, { ElementRef } from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import {
  TextGroup,
  TextGroupPrimaryProps,
  TextGroupProps
} from '@sc/components/TextGroup'

type LabelPrimaryProps = TextGroupPrimaryProps & { htmlFor: string }

const LabelPrimary = ({ css, ...props }: LabelPrimaryProps) => (
  <TextGroup.Primary
    as={LabelPrimitive.Root}
    css={{ userSelect: 'none', ...css }}
    {...props}
  />
)

const LabelSecondary = (props: TextGroupPrimaryProps) => (
  <TextGroup.Secondary {...props} />
)

type LabelRef = ElementRef<typeof TextGroup.Root>
export type LabelProps = {
  children: TextGroupProps['children']
  variant?: TextGroupProps['variant']
  disabled?: boolean
} & TextGroupProps

const LabelContainer = React.forwardRef<LabelRef, LabelProps>(
  ({ disabled, variant = 'small', ...props }, ref) => (
    <TextGroup.Root
      variant={variant}
      level={disabled ? 'secondary' : 'primary'}
      ref={ref}
      {...props}
    />
  )
)

export const Label = {
  Root: LabelContainer,
  Primary: LabelPrimary,
  Secondary: LabelSecondary
}
