import React, { useRef, ComponentProps, ElementRef } from 'react'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { Button, ButtonProps, IconButton } from '@sc/components/Button'
import { Icon } from '@sc/components/Icon'
import { Row, RowLeft } from '@sc/components/Row'
import { Body, BodyProps } from '@sc/components/Typography'
import { useScrollOnHover } from '@sc/hooks/useScrollOnHover'
import { keyframes, styled } from '@sc/theme/stitches.config'

// Animations
const slideIn = keyframes({
  from: { transform: 'translateY(var(--radix-toast-viewport-bottom))' },
  to: { transform: 'translateY(0)' }
})

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 }
})

// Components

const Close = () => {
  return (
    <ToastPrimitive.Close asChild>
      <IconButton aria-label="Dismiss toast">
        <Icon name="close" />
      </IconButton>
    </ToastPrimitive.Close>
  )
}

const StyledRoot = styled(ToastPrimitive.Root, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  paddingTop: '$paddingUITabbed',
  paddingBottom: '$paddingUITabbed',
  paddingRight: '$paddingUITabbed',
  borderRadius: '$L',
  backgroundColor: '$surfaceFg',
  boxShadow: '$levelTwo',

  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },

  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`
  }
})

const Viewport = styled(ToastPrimitive.Viewport, {
  all: 'unset',
  position: 'fixed',
  bottom: 'var(--radix-toast-viewport-bottom)',
  width: 'calc(100% - $space$paddingContainerLevelTwo)',
  left: '50%',
  transform: 'translateX(-50%)',
  stack: '1rem',
  alignItems: 'center',
  zIndex: '$toast',
  margin: 0
})

const Content = styled('div', {
  flex: 1,
  display: 'flex',
  minWidth: 0,
  flexDirection: 'row',
  userSelect: 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginRight: '$paddingUIS'
})

type ToastRef = ElementRef<typeof StyledRoot>
export type ToastProps = ToastPrimitive.ToastProps & {
  /**
   * As default the Toast will be shown until the user dismisses it.
   * @default 10000
   */
  duration?: ToastPrimitive.ToastProps['duration']
}

const Root = React.forwardRef<ToastRef, ToastProps>(
  ({ duration = 10000, children, ...props }, ref) => (
    <StyledRoot ref={ref} duration={duration} {...props}>
      <Content>{children}</Content>
      <Close />
    </StyledRoot>
  )
)

const Description = (props: BodyProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useScrollOnHover(ref)

  return (
    <ToastPrimitive.Description asChild>
      <Row variant="small" paddingZero>
        <RowLeft ref={ref} preventLineBreak>
          <Body
            variant="base"
            // Container and text padding
            css={{ paddingLeft: 'calc($paddingUIXL + $paddingUITabbed)' }}
            {...props}
          />
        </RowLeft>
      </Row>
    </ToastPrimitive.Description>
  )
}

type ActionProps = ButtonProps &
  Pick<ComponentProps<typeof ToastPrimitive.Action>, 'altText'>

const Action = ({ altText, ...props }: ActionProps) => {
  return (
    <ToastPrimitive.Action altText={altText} asChild>
      <Button variant="secondary" {...props} />
    </ToastPrimitive.Action>
  )
}

export const Toast = {
  Provider: ToastPrimitive.Provider,
  Viewport,
  Action,
  Root,
  Description
}
