import React, { ComponentProps } from 'react'
import { FormError, FieldError } from '@sc/components/form/FormError'
import { Icon, IconName } from '@sc/components/Icon'
import { styled } from '@sc/theme/stitches.config'

/**
 * The base HTML input, with some basic scaffold styling.
 */
export const BaseInput = styled('input', {
  width: '100%',
  height: '$controlHeight',
  paddingLeft: '$paddingUIXL',
  paddingRight: '$paddingUIXL',
  color: '$fgHigh',
  fontSize: '$base',
  fontFamily: '$octave',
  lineHeight: '$3',
  fontWeight: '$normal',
  backgroundColor: 'transparent',
  borderWidth: '$control',
  borderColor: '$strokePrimary',
  borderStyle: 'solid',
  borderRadius: '$S',

  '&:disabled': {
    borderColor: '$strokeSecondary',
    color: '$fgLow'
  },

  '&:focus': {
    borderColor: '$strokeFocus'
  },

  '&:focus-visible': { boxShadow: '$focus', outline: 'none' },

  '&[aria-invalid="true"]': { borderColor: '$fgMedDanger' }
})

const InputGroup = styled('div', {
  width: '100%',
  stack: '$paddingBetweenMed'
})

const IconContainer = styled('div', {
  width: '100%',
  position: 'relative'
})

const StyledIcon = styled(Icon, {
  color: '$fgHigh',

  variants: {
    disabled: {
      true: {
        color: '$fgLow'
      }
    }
  }
})

type InputProps = ComponentProps<typeof BaseInput> & {
  /**
   * The HTML `name` attribute is required for the error messages to be accessible.
   */
  name: string
  /**
   * The `error` object toggles the error state of the Input. The error description will be rendered if the message property is defined.
   */
  error?: FieldError
  icon?: IconName
  /**
   * Toggles disabled state
   */
  disabled?: boolean
}

/**
 * A HTML input with support for error messages and icon decorators
 */
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ icon, error, ...props }, ref) => {
    const errorProps = Object.assign(
      {},
      error && { 'aria-invalid': true },
      error?.message && { 'aria-errormessage': props.name + '-errormessage' }
    )

    if (icon) {
      return (
        <InputGroup>
          <IconContainer>
            <StyledIcon
              name={icon}
              disabled={props.disabled}
              variant="small"
              css={{
                position: 'absolute',
                top: '50%',
                right: '$paddingUIL',
                transform: 'translateY(-50%)'
              }}
            />
            <BaseInput ref={ref} {...errorProps} {...props} />
          </IconContainer>

          <FormError error={error} id={props.name} />
        </InputGroup>
      )
    }

    return (
      <InputGroup>
        <BaseInput ref={ref} {...errorProps} {...props} />
        <FormError error={error} id={props.name + '-errormessage'} />
      </InputGroup>
    )
  }
)
