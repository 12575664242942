import alerts from 'joist/modules/alerts'
import { Toast } from '@sc/components/Toast'

export type OdoAlert = {
  date: number
  message: string
  timeout: number
  dismiss: () => void
  type?: 'default' | 'error' | undefined
  actions?: Array<{ label: string; callback: () => void }>
}

export const Alerts = () => {
  const list: Array<OdoAlert> = alerts.useAlerts()

  function runAction(callback: () => void, alert: OdoAlert) {
    callback()
    alert.dismiss()
  }

  return (
    <Toast.Provider>
      <Toast.Viewport />

      <>
        {list.map(alert => (
          <Toast.Root key={alert.date}>
            <Toast.Description>{alert.message}</Toast.Description>

            {alert.actions?.map((action, index) => (
              <Toast.Action
                key={index}
                onClick={() => runAction(action.callback, alert)}
                altText={action.label}
              >
                {action.label}
              </Toast.Action>
            ))}
          </Toast.Root>
        ))}
      </>
    </Toast.Provider>
  )
}
