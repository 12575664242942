import { toOdoMembership } from './mappers'
import { supabase } from './supabase'

export const authWorkspaces = async (): Promise<string[] | null> => {
  const {
    data: { session }
  } = await supabase.auth.getSession()

  if (!session) return null

  const { data } = await supabase
    .from('memberships')
    .select()
    .eq('user_id', session.user.id)
    .throwOnError()
    .select()

  return data.map(({ space_id }) => space_id)
}

export const fetcher = async (spaceId: string) => {
  const { data } = await supabase
    .from('memberships')
    .select('*, user:user_id(id, email, name)')
    .eq('space_id', spaceId)
    .throwOnError()

  return data.map(toOdoMembership)
}

export const updateMembership = async (
  id: string,
  accessLevels: AccessLevel[]
) => {
  return supabase
    .from('memberships')
    .update({
      access_level: accessLevels
    })
    .eq('id', id)
    .throwOnError()
}

export const removeMembership = async (id: string) => {
  return supabase.from('memberships').delete().eq('id', id).throwOnError()
}
